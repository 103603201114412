@import "../Sass/variables";

.no-padding {
  padding:0!important;
}
.inline-block {
  display: inline-block;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  -webkit-transition: all .3s ease-in 0s;
  -moz-transition: all .3s ease-in 0s;
  -o-transition: all .3s ease-in 0s;
  transition: all .3s ease-in 0s;
}

iframe {
  border: 0;
}

a, a:focus, a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus, a:hover {
  color: $primary-color;
}

.btn-main {
  background: $primary-color;
  border:1px solid $primary-color;
  color: $light;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 12px 40px;
  text-transform: uppercase;
  border-radius: 40px;
  &:hover {
    color: $light;
  }
}
.btn-transparent {
  color: $light;
  border:1px solid $light;
  display: inline-block;
  font-size: 13px;
  letter-spacing: 1px;
  padding: 14px 35px;
  text-transform: uppercase;
  border-radius: 40px;
  &:hover {
    color: $light;
  }
}


.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}

.section {
  padding:100px 0;
}

.btn:focus {
  color: #ddd;
}
#home {
  position: relative;
}
.section {
  padding: 100px 0;
}
.section-sm {
  padding: 70px 0;
}
.section-xs {
  padding: 40px 0;
}


#preloader {
  background: #fff;
  height: 100%;
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999999999;
}




.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}


.parallax-section {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}



// Preloader
.preloader {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  position: absolute;
}
.preloader span {
  position: absolute;
  display: block;
  bottom: 0;
  width: 9px;
  height: 5px;
  border-radius: 5px;
  background: #fff;
  -webkit-animation: preloader 2s infinite ease-in-out;
  animation: preloader 2s infinite ease-in-out;
}
.preloader span:nth-child(2) {
  left: 11px;
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}
.preloader span:nth-child(3) {
  left: 22px;
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}
.preloader span:nth-child(4) {
  left: 33px;
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}
.preloader span:nth-child(5) {
  left: 44px;
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}
.preloader span:nth-child(6) {
  left: 55px;
  -webkit-animation-delay: 1000ms;
  animation-delay: 1000ms;
}

@-webkit-keyframes preloader {
  0% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: rgba(0, 0, 0, 0.1);
  }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    background: $primary-color;
  }
  50%,100% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: rgba(0, 0, 0, 0.1);
  }
}

@keyframes preloader {
  0% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background:#fff;
  }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    background: #3498db;
  }
  50%,100% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: #fff;
  }
}


// Common Styles
.title {
  padding-bottom: 50px;
  overflow: hidden;
  h2 {
    font-weight: 600;
    font-size: 35px;
    color: #232323;
  }
  p {
    color: #848484;
    margin:0 auto;
  }
  &.title-white {
    h2 {
      color: $light;
    }
  }
}
.border {
  height: 2px;
  margin: 20px auto 20px;
  position: relative;
  width: 80px;
  background: $primary-color;
}

.bg-gray {
  background: #f9f9f9;
}



.header-bradcrumb {
  background: transparent;
  a , .active {
    color:#cfcfcf;
    font-weight: 200;
    font-family: $primary-font;
  }
  a:hover {
    color: $primary-color;
  }
}



// Color Switcher

.colors-switcher {
    width: 100px;
    padding: 20px 18px 14px 20px;
    position: fixed;
    z-index: 1993;
    top: 90px;
    right: 0;
    background-color: #fff;
    border-radius: 2px 0 0 2px;
    border: 1px solid rgba(0,0,0,.1);
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    #show-panel {
      position: absolute;
      left: -38px;
      top: 15px;
      width: 38px;
      height: 38px;
      z-index: 1992;
      display: block;
      background-color: #fff;
      border-radius: 2px 0 0 2px;
      border: 1px solid rgba(0,0,0,.1);
      border-right: none;
      cursor: pointer;
      i {
        color: #3D3D3D;
        font-size: 20px;
        line-height: 38px;
        margin-left: 10px;
    }
  }
}


  .colors-list {
    margin:0;
    padding:0;
    li {
      display: inline-block;
      margin-bottom: 2px;
      margin-right: 4px;
      a {
        text-align: center;
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 2px;
        transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        cursor: pointer;
      }
    }
  }
  .colors-switcher .colors-list a.light-red {
      background-color: #FF432E;
  }
  .colors-switcher .colors-list li a:hover {
      box-shadow:inset 0 0 6px rgba(0,0,0,.4);
  }
  .colors-switcher .colors-list a.light-red {
      background-color:#FF432E;
  }
  .colors-switcher .colors-list a.blue{
      background-color:#28ABE3;
  }
  .colors-switcher .colors-list a.light-blue {
      background-color:#69D2E7;
  }
  .colors-switcher .colors-list a.green {
      background-color:#5BB12F;
  }
  .colors-switcher .colors-list a.light-green {
      background-color:#BCCF02;
  }
  .colors-switcher .colors-list a.yellow {
      background-color:#FED136;
  }


.slick-slide {
  outline: none;
}





