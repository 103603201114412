
// Variables
$light: #fff;
$primary-color: #050a44;
$secondary-color: #0a21c0;
$light-color: #2c2e3a;
$black: #141619;
$grey: #b3b4bd;
$primary-font:'Source Sans Pro', sans-serif;
$icon-font: 'themefisher-font';
$secondary-font: 'Catamaran', sans-serif;


