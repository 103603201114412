@import "../Sass/variables";
@import "../Sass/animations.scss";
@import url('https://fonts.googleapis.com/css?family=Catamaran:400,500,600,700,700|Source+Sans+Pro:400,600');

@font-face {
    font-family: myFirstFont;
    src: url(../Fonts/Zeniq.ttf);
}

/*--------------------------------------------------------------
# Navbar
--------------------------------------------------------------*/
header {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 2% 5%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.3);
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;

    .logo {
        width: 30%;

        img {
            width: 50%;
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
        }
    }

    .links {
        width: 50%;

        a {
            // font-family: $header-font;
            font-weight: bold;
            margin-right: 7%;
            padding-bottom: 1%;
            border-bottom: 2px solid #fff;
            text-transform: uppercase;
            text-decoration: none;
            color: $primary-color;

            &:hover {
                color: rgb(198, 6, 6);
                transition: .2s all ease-in-out;
                border-bottom: 2px solid rgb(198, 6, 6);
            }
        }
    }

    .menu {
        // width: 40px;
        // height: 40px;
        // fill: rgb(198, 6, 6);
        // display: none;
        // margin-right: 10%;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.53);
        padding: 2% 5%;
        border-radius: 10px;
        background: rgb(22, 124, 164);
        background: linear-gradient(90deg, rgba(22, 124, 164, 1) 0%, rgba(111, 181, 204, 1) 100%);
        display: none;

        svg {
            fill: #fff;
            width: 30px;
            height: 30px;
        }

        p {
            margin: 0;
            margin-right: 10%;
            color: #fff;
        }
    }
}

#navbar-mobile {
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    background: rgb(22, 124, 164);
    background: linear-gradient(90deg, rgba(22, 124, 164, 1) 0%, rgba(111, 181, 204, 1) 100%);
    width: 60%;
    height: 100%;
    padding: 5%;
    // background: $gradient;
    animation: slide-in-right .5s;

    .links {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 30%;

        a {
            margin-bottom: 10%;
            font-size: 4.5vw;
            color: #fff;
            border: none;

            &:last-child {
                background: transparent;
                color: #fff;
                border-radius: 0;
                margin-left: 0;
                padding: 0;
                border: none;
                box-shadow: none;
            }
        }
    }

    .cross {
        width: 30px;
        height: 30px;
        padding: 2%;
        fill: #fff;
        margin-left: auto;
        position: absolute;
        top: 2%;
        right: 10%;
    }
}

/*--------------------------------------------------------------
# Slider
--------------------------------------------------------------*/


.hero-slider {

    .alice-carousel__wrapper {
        // padding-top: 5%;
        position: relative;
        // padding: 3% 0 5% 0;

    }

    .__active {

        .slider-item {


            h1 {
                animation: slide-in-down 1s 1;
            }

            p {
                animation: blur-in 1s 1;
            }

            .btn {
                animation: slide-in-up 1s 1;
            }
        }
    }

    .alice-carousel__next-btn {
        display: none;

        img {
            width: 70px;
            position: absolute;
            top: 50%;
            right: 5%;
            transform: translate(-50%, -50%);
            border-radius: 5px;
            background: $secondary-color;
            border-radius: 50%;
            padding: 1%;
            transition: .5s all ease;
            animation: blur-in 1s 1;
        }
    }

    .alice-carousel__prev-btn {
        display: none;

        img {
            width: 70px;
            position: absolute;
            top: 45%;
            left: 5%;
            transform: translate(-50%, -50%);
            border-radius: 5px;
            background: $secondary-color;
            border-radius: 50%;
            padding: 1%;
            transform: scaleX(-1);
            transition: .5s all ease;
            animation: blur-in 1s 1;
        }
    }

    &:hover {
        transition: .5s all ease;

        .alice-carousel__prev-btn,
        .alice-carousel__next-btn {
            display: block;
            transition: .5s all ease;

        }
    }

    .slider-item {
        position: relative;
        z-index: 4;


        h1,
        p {
            margin-bottom: 2%;
        }

        h1 {
            text-shadow: 1px 1px 2px rgba($color: #000, $alpha: 0.3);
            animation: slide-in-down 1s 1;
            font-size: 60px;
            margin: auto;
            line-height: 150%;

            span {
                font-family: MyFirstFont;
                text-shadow: 1px 1px 1px rgba($color: #000, $alpha: 0.93);
                font-size: 60px;
                // background: -webkit-linear-gradient(#64f9c7, #d5ffb6);
                // // background: -webkit-linear-gradient(#64f9c7, #9ac9ff);
                // // background: -webkit-linear-gradient(#aa64f9, #9ac9ff);
                // // background: -webkit-linear-gradient(#f964aa, #9ac9ff);
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
                background: linear-gradient(90deg, rgba(22, 124, 164, 1) 0%, rgba(111, 181, 204, 1) 100%);
                color: #fff;
                padding: 1% 3%;
                border-radius: 20px;
                box-shadow: inset 2px 2px 5px rgba($color: #000, $alpha: 0.53)
            }
        }

        p {
            font-size: 25px;
            animation: blur-in 1s 1;


        }


        .btn {
            animation: slide-in-up 1s 1;
            background: #fff;
            color: $primary-color;
            font-weight: bold;

            &:hover {
                transform: scale(1.1);
                transition: .5s all ease;
            }
        }
    }
}

.about-2 {

    img {
        border: 5px solid $primary-color;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.3);
    }

    ul {
        padding-left: 6%;

        li {
            list-style-type: unset;
            color: #434343;
            font-size: 18px;
            line-height: 1.65;
            font-weight: bold;
        }
    }

    .col-md-6 {
        padding: 0 2%;

        a {
            background: rgb(198, 6, 6);
            font-weight: bold;
            border: none;
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.3);
            margin-top: 5%;
        }
    }
}

.service-2 {
    background: #F7F7F7;

    .service-item {
        border: 1px solid rgb(198, 6, 6) !important;
        padding: 2% 5% !important;
        height: 32vh;
        margin-bottom: 4% !important;

        h4 {
            font-size: 20px;
        }

        p {
            font-size: 16px;
            text-align: left;
        }

        svg {
            width: 30px;
        }
    }
}

.call-to-action {
    h2 {
        font-size: 45px;
        text-shadow: 1px 1px 2px rgba($color: #000, $alpha: 0.3);
        margin-bottom: 2%;

    }

    p {
        font-size: 20px;
        margin: auto;
        width: 60%;
        margin-bottom: 2%;
    }

    a {
        background: rgb(198, 6, 6);
        color: #fff;
    }
}

.post-item {
    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.53);

    img {
        height: 35vh;
        width: 100%;
        object-fit: cover;
    }

    .btn {
        background: rgb(198, 6, 6);
        color: #fff;
        border: none;
        font-weight: bold;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.3);

        &:hover {
            transform: scale(1.1);
            transition: .5s all ease;
        }
    }

    p {
        font-size: 16px;
    }
}

.services-body {
    .services-button {
        display: flex;
        justify-content: center;
        align-items: center;


        .services-card {

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: rgba($color: $grey, $alpha: 0.3);
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.3);
            margin-left: 1%;
            width: calc(100% / 5);
            cursor: pointer;
            // border-radius: 0 0 20px 20px;
            // border: 1px solid #000;

            svg {
                fill: rgb(198, 6, 6);
                width: 50px;
                height: 50px;
                margin-bottom: 7%;
            }

            img {
                width: 100%;
                object-fit: cover;
            }

            &:hover {
                background: rgb(198, 6, 6);
                box-shadow: 2px 2px 5px rgba(198, 6, 6, .5);
                transition: .5s all ease;
                transform: scale(1.05);
            }



            h3 {
                font-size: 23px;
                text-align: center;
                margin-bottom: 0;
                padding: 7% 1%;
            }
        }
    }

    .services-content {
        padding: 5% 1% 0 1%;

        h3 {
            color: #002e98;
        }

        ul {
            padding: 1% 5%;

            li {
                font-size: 18px;
                line-height: 200%;
                list-style-type: circle;
            }
        }

        ol {
            padding-left: 5%;

            li {
                list-style-type: decimal;
            }
        }
    }
}

.services {
    background: #fff !important;
}

.partners {

    // margin-bottom: 5%;
    .partners-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .alice-carousel__prev-btn,
        .alice-carousel__next-btn {
            width: 40px;
            height: 40px;
            fill: rgba(198, 6, 6, 1);
            margin: auto;
            position: absolute;
            top: 140%;
            left: 50%;
            display: none;
        }

        .alice-carousel__prev-btn {
            transform: scaleX(-1);
            left: 47%;
        }

        img {
            width: 15%;
            margin: 2% 5%;
            display: block;
            // margin: auto;
        }
    }
}

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) {

    header {
        padding: 3% 5%;

        .logo {
            width: 80%;

            img {
                filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));

            }
        }

        .links {
            display: none;
        }

        .menu {
            display: flex;
        }
    }

    .hero-slider {
        .slider-item {
            h1 {
                font-size: 30px;
                line-height: 130%;
                width: 100%;
                margin-bottom: 5%;

                span {
                    font-size: 30px;
                    border-radius: 10px;
                    margin: 5% 0;
                    padding: 2% 3%;
                }
            }

            p {
                font-size: 16px;
                margin-bottom: 5%;
            }
        }
    }

    .section {
        padding: 5% 3% !important;
    }

    .service-2 {
        .service-item {
            height: 100%;

            h4 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }
        }
    }

    .services-body {
        .services-button {
            flex-wrap: wrap;

            .services-card {
                width: calc(100% / 2.2);
                margin: 1%;

                h3 {
                    font-size: 16px;
                }
            }
        }

        .services-content {
            ul {
                li {
                    font-size: 16px;
                    line-height: 160%;
                    margin-bottom: 3%;

                    b {
                        font-size: 19px;
                    }
                }
            }
        }
    }

    p {
        font-size: 16px !important;
    }

    .call-to-action {
        h2 {
            width: 100%;
            font-size: 22px;
            line-height: 140% !important;
        }

        p {
            width: 100%;
            font-size: 14px !important;
        }
    }

    .partners {
        .partners-body {
            justify-content: space-between;

            img {
                width: 30%;
                margin: 4% 5%;
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {


    header {
        padding: 3% 5%;

        .logo {
            width: 40%;

            img {
                filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));

            }
        }

        .links {
            display: none;
        }

        .menu {
            display: flex;
        }
    }

    .hero-slider {
        .slider-item {
            h1 {
                font-size: 40px;
                line-height: 130%;
                width: 100%;
                margin-bottom: 5%;

                span {
                    font-size: 45px;
                }
            }

            p {
                font-size: 20px;
                margin-bottom: 5%;
            }
        }
    }

    .service-2 {
        .service-item {
            height: 40vh;

            h4 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }
        }
    }

    .services-body {
        .services-button {
            flex-wrap: wrap;

            .services-card {
                width: calc(100% / 3.2);
                margin: 1%;

                h3 {
                    font-size: 16px;
                }
            }
        }

        .services-content {
            ul {
                li {
                    font-size: 16px;
                    line-height: 140%;
                }
            }
        }
    }

    .call-to-action {
        h2 {
            width: 100%;
            font-size: 32px;
            line-height: 140% !important;
        }

        p {
            width: 100%;
            font-size: 17px !important;
        }
    }

    .partners {
        .partners-body {
            justify-content: space-between;

            img {
                width: 21%;
                margin: 4% 2%;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {


    header {
        padding: 3% 5%;

        .logo {
            width: 80%;

            img {
                filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));

            }
        }

        .links {
            display: none;
        }

        .menu {
            display: flex;
        }
    }

    .hero-slider {
        .slider-item {
            h1 {
                font-size: 40px;
                line-height: 130%;
                width: 100%;
                margin-bottom: 5%;

                span {
                    font-size: 45px;
                }
            }

            p {
                font-size: 20px;
                margin-bottom: 5%;
            }
        }
    }

    .service-2 {
        .service-item {
            height: 35vh;

            h4 {
                font-size: 18px;
            }

            p {
                font-size: 16px;
            }
        }
    }

    .services-body {
        .services-button {
            flex-wrap: wrap;

            .services-card {
                width: calc(100% / 2.2);
                margin: 1%;

                h3 {
                    font-size: 16px;
                }
            }
        }

        .services-content {
            ul {
                li {
                    font-size: 16px;
                    line-height: 140%;
                }
            }
        }
    }

    .call-to-action {
        h2 {
            width: 100%;
            font-size: 32px;
            line-height: 140% !important;
        }

        p {
            width: 100%;
            font-size: 17px !important;
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {

    header {
        .links {
            width: 70%;
            text-align: center;

            a {
                margin-right: 5%;
                font-size: 2vw;
            }
        }
    }

    .hero-slider {
        .slider-item {
            h1 {
                font-size: 35px;
                line-height: 130%;
                width: 100%;
                margin-bottom: 5%;

                span {
                    font-size: 40px;
                }
            }

            p {
                font-size: 25px;
                margin-bottom: 5%;
            }
        }
    }

    #about {
        .row {
            flex-direction: column;

            div {
                width: 100%;

                &:last-child {
                    margin-top: 2%;
                }
            }
        }
    }

    .service-2 {

        .service-item {
            height: 35vh;

            h4 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }
        }
    }


    .services-body .services-button .services-card h3 {
        font-size: 18px;
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {

    header {
        .links {
            width: 70%;
            text-align: center;

            a {
                margin-right: 5%;
                font-size: 2vw;
            }
        }
    }

    .hero-slider {
        .slider-item {
            h1 {
                font-size: 50px;
                line-height: 130%;
                width: 100%;
                margin-bottom: 5%;

                span {
                    font-size: 60px;
                }
            }

            p {
                font-size: 25px;
                margin-bottom: 5%;
            }
        }
    }

    #about {
        .row {
            flex-direction: column;

            div {
                width: 100%;

                &:last-child {
                    margin-top: 2%;
                }
            }
        }
    }

    .service-2 {

        .service-item {
            height: 35vh;

            h4 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }
        }
    }

    .services-body .services-button .services-card h3 {
        font-size: 18px;
    }
}

@media (min-width: 1025px) and (max-width: $xl) {

    header {
        .links {
            width: 60%;
            text-align: center;

            a {
                margin-right: 5%;
            }
        }
    }


}