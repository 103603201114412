@import url('https://fonts.googleapis.com/css?family=Catamaran:400,500,600,700,700|Source+Sans+Pro:400,600');
@import "../Sass/variables";

body {
  line-height: 1.4;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
}
p {
  font-family: $primary-font;
  color: #848484;
  font-size:16px;
  line-height: 1.65;
}
h1,h2,h3,h4,h5,h6 {
	font-family: $secondary-font;
	font-weight: 600;
}